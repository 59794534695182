// API
// export const apiUrl = "http://localhost:5000/api/"
export const apiUrl = "https://api-dsr.modernize.shop/api/"
// export const apiUrl = "https://api-sdsr.modernize.shop/api/"
// export const apiUrl = "https://demo-api-rktv.modernize.shop/api/"
// export const apiUrl = "https://sorrycoffee-api.modernize.shop/api/"
// export const apiUrl = "https://rekksekk-api.modernize.shop/api/"
// export const apiUrl = "https://croal-api.modernize.shop/api/"
// export const apiUrl = "https://maxi-api.modernize.shop/api/"
// export const apiUrl = "https://ssc-api.modernize.shop/api/"
// export const apiUrl = "https://api-msct.modernize.shop/api/"
// export const apiUrl = "https://api-esc.modernize.shop/api/"
// export const apiUrl = "https://api-bela.modernize.shop/api/"
// export const apiUrl = "https://cccb-api.modernize.shop/api/"
// export const apiUrl = "https://sys-api.boromcafe.com/api/"
// export const apiUrl = "https://mindfulness-api.modernize.shop/api/"
// export const apiUrl = "https://calla-api.modernize.shop/api/"
// export const apiUrl = "https://downtown-api.modernize.shop/api/"
// export const apiUrl = "https://vichetmart-api.modernize.shop/api/"
// export const apiUrl = "https://67plastic-api.modernize.shop/api/"
// export const apiUrl = "https://HengThou-api.modernize.shop/api/"
// export const apiUrl = "https://tzonesport-api.modernize.shop/api/"
// export const apiUrl = "https://nova1-api.modernize.shop/api/"
// export const apiUrl = "https://bsc-api.modernize.shop/api/"
// export const apiUrl = "https://borom-api.modernize.shop/api/"
// export const apiUrl = "https://demo-gyss-api.modernize.shop/api/"
// export const apiUrl = "https://pos-api.novacoffeeifl.com/api/"
// export const apiUrl = "https://phojang-api.modernize.shop/api/"

// export const webUrl = "http://localhost:3000/digital-scan/menu"
export const webUrl = "https://dsr.modernize.shop/digital-scan/menu"
// export const webUrl = "https://sdsr.modernize.shop/digital-scan/menu"
// export const webUrl = "https://demo-rktv.modernize.shop/digital-scan/menu"
// export const webUrl = "https://sorrycoffee.modernize.shop/digital-scan/menu"
// export const webUrl = "https://croal.modernize.shop/digital-scan/menu"
// export const webUrl = "https://rekksekk.modernize.shop/digital-scan/menu"
// export const webUrl = "https://67plastic.modernize.shop/digital-scan/menu"
// export const webUrl = "https://HengThou.modernize.shop/digital-scan/menu"
// export const webUrl = "https://tzonesport.modernize.shop/digital-scan/menu"
// export const webUrl = "https://nova1.modernize.shop/digital-scan/menu"
// export const webUrl = "https://bsc.modernize.shop/digital-scan/menu"
// export const webUrl = "https://borom.modernize.shop/digital-scan/menu"
// export const webUrl = "https://demo-gyss.modernize.shop/digital-scan/menu"
// export const webUrl = "https://poss.novacoffeeifl.com/digital-scan/menu"
// export const webUrl = "https://phojang.modernize.shop/digital-scan/menu"

// Color
export const red = "#FF0000"
export const green = "#01C400"
export const blue = "#001DC4"
export const yellow = "#FFFF00"
export const orange = "#FFA500"
export const modernizeti = "#25B35F"